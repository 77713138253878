var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { show: _vm.showConfirmExit, showClose: true },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v("Cerrar sesión")
      ]),
      _c(
        "form",
        {
          ref: "CloseSessionForm",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.confirmExit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row mt-1" }, [
              _c("div", { staticClass: "col-xl-12" }, [
                _vm._v("¿Está seguro que desea cerrar la sesión?")
              ])
            ])
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-4 text-right" }),
              _c("div", { staticClass: "col-4 text-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "link",
                    on: {
                      click: function($event) {
                        return _vm.$emit("close")
                      }
                    }
                  },
                  [_vm._v("Cancelar")]
                )
              ]),
              _c(
                "div",
                { staticClass: "col-4 text-right" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "my-4 error",
                      attrs: { type: "primary", nativeType: "submit" }
                    },
                    [_vm._v("Salir")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }