var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-select", {
    attrs: {
      label: "Estado",
      options: _vm.states,
      "v-model": _vm.value,
      id: "estate",
      required: ""
    },
    on: {
      blur: function($event) {
        return _vm.updateSelection()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }