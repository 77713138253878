<template>
  <modal :show="showConfirmExit" v-on:close="$emit('close')" :showClose="true">
    <div slot="header">Cerrar sesión</div>
    <form @submit.prevent="confirmExit" ref="CloseSessionForm">
      <div class="container-fluid">
        <div class="row mt-1">
          <div class="col-xl-12">¿Está seguro que desea cerrar la sesión?</div>
        </div>
      </div>
      <div slot="footer">
        <div class="row align-items-center">
          <div class="col-4 text-right"></div>
          <div class="col-4 text-right">
            <a @click="$emit('close')" class="link">Cancelar</a>
          </div>
          <div class="col-4 text-right">
            <base-button type="primary" class="my-4 error" nativeType="submit">Salir</base-button>
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>
<script>
export default {
  name: "logout-confirmation-modal",
  props: {
    showConfirmExit: Boolean
  },
  methods: {
    confirmExit() {
      this.$router.push({ path: "/logout" });
    }
  }
};
</script>
<style lang="scss" scoped>
.link {
  &:hover {
    cursor: pointer;
  }
}
</style>