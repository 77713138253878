import instance from '../axios.ts';
import enums from '../enums/enums'
let time = 1;
let extraTimeOut = 300000;
export const tokenCall = ({ ...args }) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      // const requestData = {
      //   username: args.data.username,
      //   password: args.data.password,
      //   timezone: args.data.timezone,
      //   currentTimeOffset: args.data.currentTimeOffset
      // };
      resolve(instance.post('/auth/loginv2', args));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const refreshToken = (args) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put('/auth/refresh', { refreshToken: args }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getUserInfo = ({ ...args }) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/users/${args.data.userId}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getSalesResume = ({ ...args }) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('/sales/periodsales', args.data));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getBranches = (enterpriseId) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/branch/list/${enterpriseId}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getZumaLinkInfo = (code) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      let entity = {}
      entity.longCode = code
      resolve(instance.post(`reports/code/data`, entity));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const processPayment = (entity) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`transactions/code/validation`, entity));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getClient = (type, idClient) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      switch (type) {
        case enums.C_ACCOUNT:
          url = '/users/account/';
          break;
        case enums.ADMIN:
          url = '/users/admin/';
          break;
        case enums.EXTERNAL:
          url = '/users/external/';
          break;
        case enums.DEVICE:
          url = '/users/device/';
          break;
        case enums.PARTNER:
          url = '/users/partner/';
          break;

        default:
          break;
      }
      resolve(instance.get(`${url}${idClient}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const searchItemsByType = (type, dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      switch (type) {
        case enums.ACCOUNT:
          url = '/accounts/simple/list';
          break
        case enums.ENTERPRISE:
          url = '/accounts/simple/list';
          break
        case enums.BRANCH:
          url = '/enterprises/simple/list';
          break
        case enums.DEVICE:
        case enums.EXTERNAL:
          url = '/branches/simple/list';
          break
        case enums.PARTNER:
          url = '/accounts/simple/partner/list'
          break
        case enums.C_ACCOUNT:
          url = '/accounts/simple/list';
          break
        case enums.P_ACCOUNT:
          url = '/users/partners/list';
          break
        default:
          break;
      }
      resolve(instance.post(`${url}`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getItemInfoByType = (type, id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      switch (type) {
        case enums.ACCOUNT:
          url = '/accounts/';
          break;
        case enums.ENTERPRISE:
          url = '/enterprises/';
          break;
        case enums.BRANCH:
          url = '/branches/';
          break;
        case enums.DEVICE:
          url = '/devices/';
          break;
        default:
          break;
      }
      resolve(instance.post(`${url}${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

// General 

export const getListByType = (type, dto, ownerId) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      switch (type) {
        case enums.ACCOUNT:
          url = '/accounts/simple/list';
          break;
        case enums.ENTERPRISE:
          url = '/enterprises/simple/list';
          if (ownerId > 0) {
            dto.accountId = ownerId
          }
          break;
        case enums.BRANCH:
          url = '/branches/simple/list';
          if (ownerId > 0) {
            dto.enterpriseId = ownerId
          }
          break;
        case enums.DEVICE:
          url = '/devices/list';
          if (ownerId > 0) {
            dto.branchId = ownerId
          }
          break;
        default:
          break;
      }
      resolve(instance.post(`${url}`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const sendTicket = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('/tickets/mail', dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const validateAmount = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('payments/validation', dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const generateZumaLink = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('transactions/link', dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const generateCodi = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('transactions/codi', dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getTicketsList = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('tickets/list', dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const toggleTickets = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`tickets`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getTicketInfo = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`tickets/${id}`,));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getProductCode = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`accounts/linkcode`, {}));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getProductCodeEnterp = (idEnterprise) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`enterprises/linkcode`, {idEnterprise}));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const sendLinkByEmail = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`transactions/link/send`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

// users

export const createClient = (type, body) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      switch (type) {
        case enums.C_ACCOUNT:
          url = '/users/account'
          break;
        case enums.EXTERNAL:
          url = '/users/external'
          body.idEnterprise = undefined
          body.userLevel = undefined
          break;
        case enums.PARTNER:
          url = '/users/partner'
          body.idEnterprise = undefined
          body.idBranch = undefined
          body.userLevel = undefined
          break;
        case enums.DEVICE:
          url = '/users/devices'
          break;
        case enums.ADMIN:
          url = '/users/admin'
          body.idEnterprise = undefined
          body.idBranch = undefined
          body.userLevel = undefined
          break;
        default:
          break;
      }
      resolve(instance.post(`${url}`, body));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const disableItemByType = (type, row) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      switch (type) {
        case enums.ACCOUNT:
          url = `/users/account/toggle/${row.idUser}`;
          break;
        case enums.C_ACCOUNT:
          url = `/users/account/toggle/${row.idUser}`;
          break;
        case enums.ADMIN:
          url = `/users/admin/toggle/${row.idUser}`;
          break;
        case enums.EXTERNAL:
          url = `/users/external/toggle/${row.idUser}`;
          break;
        case enums.DEVICE:
          url = `/users/device/toggle/${row.idUser}`;
          break;
        case enums.PARTNER:
          url = `/users/partner/toggle/${row.idUser}`;
          break;
        default:
          break;
      }
      resolve(instance.put(`${url}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const updateClient = (type, body) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      switch (type) {
        case enums.C_ACCOUNT:
          url = `/users/account/${body.idUser}`
          break;
        case enums.EXTERNAL:
          url = `/users/external/${body.idUser}`
          break;
        case enums.PARTNER:
          url = `/users/partner/${body.idUser}`
          break;
        case enums.DEVICE:
          url = `/users/devices/${body.idUser}`
          break;
        case enums.ADMIN:
          url = `/users/admin/${body.idUser}`
          break;
        default:
          break;
      }
      resolve(instance.put(`${url}`, body));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getClientsByType = (type, body) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      switch (type) {
        case enums.C_ACCOUNT:
          url = '/users/accounts/list'
          break;
        case enums.EXTERNAL:
          url = '/users/externals/list'
          break;
        case enums.PARTNER:
          url = '/users/partners/list'
          break;
        case enums.DEVICE:
          url = '/users/devices/list'
          break;
        case enums.ADMIN:
          url = '/users/admins/list'
          break;
        default:
          break;
      }
      resolve(instance.post(`${url}`, body));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const assingAccounts = (accounts) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/accounts/partner/link`, accounts));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const changeUserPassword = (body) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/users/password/admin`, body));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getUserGroups = (type) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var dto = {}
      switch (type) {
        case enums.C_ACCOUNT:
          dto = { userGroupScope: 'U' }
          break;
        case enums.ADMIN:
          dto = { userGroupScope: 'A' }
          break;
        default:
          reject(new Error(""))
          return
      }
      resolve(instance.post(`/usersgroups`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getOwnData = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/users/own/data`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const changePassword = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/users/password`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const updateProfile = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/users/profile`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});


// Accounts

export const saveAccounts = (account) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('/accounts', account));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const updateAccount = (account) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put('/accounts', account));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const disableAccount = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/accounts/toggle/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getAccounts = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      instance.defaults.timeout = extraTimeOut
      resolve(instance.post(`/accounts/list`, dto));
      instance.defaults.timeout = 20000
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});


export const getAccountInfo = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/accounts/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getPartnerAccounts = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/accounts/partner/list`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getFees = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/accounts/fees`, { idAccount: Number(id) }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveFees = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/accounts/fees`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveProduct = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/accounts/paymentsproducts`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveProductDevice = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      // let dtu =dto.paymentsProducts;
      // let dtu = {
      //   paymentsProducts: dto.paymentsProducts,
      // }
      resolve(instance.put(`/users/device/products/${dto.idUserDevice}`, dto.paymentsProducts));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveProductEnterprise = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/enterprises/paymentsproducts`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getAccountsPaymentsProducts = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/accounts/paymentsproducts`, { idAccount: Number(id) }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getEnterprisesPaymentsProducts = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/enterprises/paymentsproducts`, { idEnterprise: Number(id) }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

// export const getDevicesPaymentsProducts = (id) => new Promise((resolve, reject) => {
//   setTimeout(() => {
//     try {
//       resolve(instance.get(`/users/device/products/`, { idUserDevice: Number(id) }));
//     } catch (err) {
//       reject(new Error(err));
//     }
//   }, time);
// });

export const getDevicesPaymentsProducts = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/users/device/products/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getSurveyData = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/transsurveys/list`, { idBranch: Number(id) }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});
// Enterprises

export const getEnterprises = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      instance.defaults.timeout = extraTimeOut
      resolve(instance.post(`/enterprises/list`, dto));
      instance.defaults.timeout = 20000

    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getEnterprisesSimpleList = (idAccount) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      let dto = {
        idAccount: idAccount,
        searchTerm: "",
        offset: 0,
        limit: 100000000,
        status: "ALL"
      }
      resolve(instance.post(`/enterprises/simple/list`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getEnterpriseInfo = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/enterprises/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveEnterprise = (enterprise) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('/enterprises', enterprise));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const updatedEnterprise = (enterprise) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put('/enterprises', enterprise));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const disableEnterprise = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/enterprises/toggle/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getBusinessActivities = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/businessactivities`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getFeesEnterprises = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/enterprises/fees`, { idEnterprise: Number(id) }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveFeesEnterprises = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/enterprises/fees`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

// branches

export const getBranchesV2 = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      instance.defaults.timeout = extraTimeOut
      resolve(instance.post(`/branches/list`, dto));
      instance.defaults.timeout = 20000
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getBranchesSimpleList = (idEnterprise, idAccount) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      let dto = {
        idEnterprise: idEnterprise,
        idAccount: idAccount,
        searchTerm: "",
        offset: 0,
        limit: 100000000,
        status: "ALL"
      }
      resolve(instance.post(`/branches/simple/list`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getActionsList = () => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/actions`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getBranchInfo = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/branches/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveSecurityBranch = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put('/pinactions', dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveBranch = (branch) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('/branches', branch));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const updateBranch = (branch) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put('/branches', branch));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const disableBranch = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/branches/toggle/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getBranchesCount = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`branches/count`, { idAccount: id, idEnterprise: null }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getBranchesTotalSum = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`branches/totalssum`, { idAccount: id, idEnterprise: null }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

// Devices

export const getDevices = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      instance.defaults.timeout = extraTimeOut
      resolve(instance.post(`/users/devices/list`, dto));
      instance.defaults.timeout = 20000
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getDeviceInfo = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/users/device/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveDevice = (device) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post('/users/device', device));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const updatedDevice = (device) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/users/device/${device.idUser}`, device));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const disableDevice = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/users/device/toggle/${id}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const createQRDevice = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/auth/code`, { idUserDevice: id }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getDeviceData = (id) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/paymentsclients`, { idUser: Number(id) }));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const saveDeviceData = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/paymentsclients`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

// payments

export const getSessions = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.post(`/reports/sessions/list`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getTransactions = (dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      var url = ''
      if (dto.sessionId > 0) {
        url = '/reports/payments/list'
      } else {
        url = '/reports/payments/list'
      }
      resolve(instance.post(url, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getTotalSession = (id, idUser) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      let dto = {
        idSession: id,
        idUser: idUser
      }
      resolve(instance.post(`reports/sessions/totals`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const getUserHierarchiesLinked = (idUser) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.get(`/users/account/hierarchies/${idUser}`));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});

export const updateUserHierarchiesLinked = (idUser, dto) => new Promise((resolve, reject) => {
  setTimeout(() => {
    try {
      resolve(instance.put(`/users/account/hierarchies/${idUser}`, dto));
    } catch (err) {
      reject(new Error(err));
    }
  }, time);
});
