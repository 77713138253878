const types = {
    ACCOUNT: 'account',
    ENTERPRISE: 'enterprise',
    BRANCH: 'branch',
    C_ACCOUNT: 'client-account',
    EXTERNAL: 'external',
    DEVICE: 'device',
    ADMIN: 'admin',
    PARTNER: 'partner',
    P_ACCOUNT: 'partner-account'
}

export default Object.freeze(types)