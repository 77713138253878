var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "pagination",
      class: [
        _vm.size && "pagination-" + _vm.size,
        _vm.align && "justify-content-" + _vm.align
      ]
    },
    [
      _c(
        "li",
        {
          staticClass: "page-item prev-page",
          class: { disabled: _vm.value === 1 }
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { "aria-label": "Previous" },
              on: { click: _vm.prevPage }
            },
            [_vm._m(0)]
          )
        ]
      ),
      _vm._l(_vm.range(_vm.minPage, _vm.maxPage), function(item) {
        return _c(
          "li",
          {
            key: item,
            staticClass: "page-item",
            class: { active: _vm.value === item }
          },
          [
            _c(
              "a",
              {
                staticClass: "page-link",
                on: {
                  click: function($event) {
                    return _vm.changePage(item)
                  }
                }
              },
              [_vm._v(_vm._s(item))]
            )
          ]
        )
      }),
      _c(
        "li",
        {
          staticClass: "page-item next-page",
          class: { disabled: _vm.value === _vm.totalPages }
        },
        [
          _c(
            "a",
            {
              staticClass: "page-link",
              attrs: { "aria-label": "Next" },
              on: { click: _vm.nextPage }
            },
            [_vm._m(1)]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", {
        staticClass: "fa fa-angle-left",
        attrs: { "aria-hidden": "true" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", {
        staticClass: "fa fa-angle-right",
        attrs: { "aria-hidden": "true" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }