var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "nav-item" },
    [
      _c(
        "router-link",
        {
          staticClass: "nav-link text-base",
          attrs: {
            to: _vm.link.path,
            target: _vm.link.target,
            href: _vm.link.path
          },
          nativeOn: {
            click: function($event) {
              return _vm.linkClick($event)
            }
          }
        },
        [
          [
            _c("i", { class: _vm.link.icon }),
            _c("span", { staticClass: "nav-link-text" }, [
              _vm._v(_vm._s(_vm.link.name))
            ])
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }