/* eslint-disable promise/param-names */
import {
  SAVE_BRANCH_TAB_STATE,
} from '../actions/branch';


const state = {
  clientComponentState: {
    activeTab:''
  },
  status: '',
};

const actions = {
  [SAVE_BRANCH_TAB_STATE]: ({commit}, data) => {
    return new Promise((resolve) => {
      commit(SAVE_BRANCH_TAB_STATE, data);
      resolve();
    });
  },
};

const mutations = {
  [SAVE_BRANCH_TAB_STATE]: (state, data) => {
      state.status = 'success';
      state.clientComponentState.activeTab = data.activeTab;
  },
  
};

export default {
  state,
  // getters,
  actions,
  mutations,
}
