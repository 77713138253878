/*

=========================================================
* Vue Argon Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Vue from "vue";
import VTooltip from "v-tooltip";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import Toasted from "vue-toasted";
import VueAxios from "vue-axios";
import Vue2Filters from "vue2-filters";
import { Vuelidate } from "vuelidate";
import bootstrapvue from 'bootstrap-vue'

import store from "./store";
import axiosInstance from "./axios.ts";
import moment from "moment";
import vSelect from "vue-select";
import VueClipboard from 'vue-clipboard2'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonEditor from 'vue-json-edit'
import Dropdown from 'vue-simple-search-dropdown';

Vue.use(Dropdown)
Vue.use(JsonEditor)
Vue.use(Loading, {
  // props
  color: 'primary'
}, {
  // slots
})
Vue.use(VueClipboard)
Vue.component("v-select", vSelect);
Vue.config.productionTip = false;
Vue.use(ArgonDashboard);
Vue.use(Toasted, {
  theme: "bubble",
  position: "top-center",
  duration: 5000
});
Vue.use(VueAxios, axiosInstance);
Vue.use(Vue2Filters);
Vue.use(VTooltip);
Vue.use(Vuelidate);
Vue.use(bootstrapvue);
Vue.filter("formatDate", value => {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("formatDateTime", value => {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
  }
});

Vue.filter("formatTime", value => {
  if (value) {
    return moment(String(value)).format("HH:mm:ss");
  }
});

Vue.filter("formatOnlyTime", value => {
  if (value) {
    return moment(String(value)).format("HH:mm");
  }
});

Vue.filter("formatTimeWithoutTimeZone", value => {
  if (value) {
    return moment.utc(String(value)).format("HH:mm:ss");
  }
});

Vue.filter("formatDateWithoutTimeZone", value => {
  if (value) {
    return moment.utc(String(value)).format("DD/MM/YYYY");
  }
});

Vue.filter("formatDateTimeWithoutTimeZone", value => {
  if (value) {
    return moment.utc(String(value)).format("DD/MM/YYYY HH:mm:ss");
  }
});

router.beforeEach((to, from, next) => {
  //this will recursively go up from child to parent, useful if utilizing children routes. otherwise, you don't need it
  let matchedObject = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.hasOwnProperty("windowRedirectAfter"));
  if (matchedObject) {
    if (matchedObject.meta.windowRedirectAfter === true) {
      // the only redirect is from login, we do this as a workaround for missing
      // scroll bar, so we redirect to root path to allow nginx work as spectect
      window.location.href = "/s";
      return;
    }
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
