var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content bg-default-zuma" }, [
    _c(
      "div",
      {
        staticClass: "header bg-gradient-app-zuma py-8 py-lg-8",
        attrs: { id: "gradient-header" }
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "header-body text-center mt--7 pb-5" }, [
            _c(
              "a",
              {
                staticClass: "navbar-brand no-margin",
                attrs: { slot: "brand", href: "http://zumapagos.com" },
                slot: "brand"
              },
              [
                _c("img", {
                  attrs: { src: "img/brand/white-logo.png", width: "100%" }
                })
              ]
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass: "separator separator-bottom separator-skew zindex-100"
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  x: "0",
                  y: "0",
                  viewBox: "0 0 2560 100",
                  preserveAspectRatio: "none",
                  version: "1.1",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("polygon", {
                  staticClass: "fill-default-zuma",
                  attrs: { points: "2560 0 2560 100 0 100" }
                })
              ]
            )
          ]
        )
      ]
    ),
    _c(
      "div",
      { staticClass: "container mt--7 pb-5" },
      [
        _c(
          "slide-y-up-transition",
          { attrs: { mode: "out-in", origin: "center top" } },
          [_c("router-view")],
          1
        )
      ],
      1
    ),
    _c("footer", { staticClass: "py-6" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "align-items-center" }, [
          _c("div", { staticClass: "col-xl" }, [
            _c("div", { staticClass: "copyright text-center" }, [
              _vm._v("\n            © " + _vm._s(_vm.year) + "\n            "),
              _c(
                "a",
                {
                  staticClass: "font-weight-bold ml-1",
                  attrs: { href: "https://www.zumapagos.com", target: "_blank" }
                },
                [_vm._v("Zuma Pagos")]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }