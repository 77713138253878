var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: [
        { "input-group": _vm.hasIcon },
        { "has-danger": _vm.error },
        { focused: _vm.focused },
        { "has-label": _vm.label || _vm.$slots.label },
        { "has-success": _vm.valid === true },
        { "has-danger": _vm.valid === false }
      ]
    },
    [
      _vm._t("label", [
        _vm.label
          ? _c(
              "label",
              { staticClass: "form-control-label", class: _vm.labelClasses },
              [
                _vm._v("\n            " + _vm._s(_vm.label) + "\n            "),
                _vm.required ? _c("span", [_vm._v("*")]) : _vm._e()
              ]
            )
          : _vm._e()
      ]),
      _vm.addonLeftIcon || _vm.$slots.addonLeft
        ? _c("div", { staticClass: "input-group-prepend" }, [
            _c(
              "span",
              { staticClass: "input-group-text" },
              [_vm._t("addonLeft", [_c("i", { class: _vm.addonLeftIcon })])],
              2
            )
          ])
        : _vm._e(),
      _vm._t(
        "default",
        [
          _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  staticClass: "form-control",
                  class: [
                    { "is-valid": _vm.valid === true },
                    { "is-invalid": _vm.valid === false },
                    _vm.inputClasses
                  ],
                  attrs: {
                    "aria-describedby": "addon-right addon-left",
                    id: _vm.id,
                    type: "date",
                    min: "1900-01-01",
                    max: "2030-12-31"
                  },
                  domProps: { value: _vm.value }
                },
                "input",
                _vm.$attrs,
                false
              ),
              _vm.listeners
            )
          )
        ],
        null,
        _vm.slotData
      ),
      _vm.addonRightIcon || _vm.$slots.addonRight
        ? _c("div", { staticClass: "input-group-append" }, [
            _c(
              "span",
              { staticClass: "input-group-text" },
              [_vm._t("addonRight", [_c("i", { class: _vm.addonRightIcon })])],
              2
            )
          ])
        : _vm._e(),
      _vm._t("infoBlock"),
      _vm._t("helpBlock", [
        _vm.error
          ? _c(
              "div",
              {
                staticClass: "text-danger invalid-feedback",
                class: { "mt-2": _vm.hasIcon },
                staticStyle: { display: "block" }
              },
              [_vm._v("\n            " + _vm._s(_vm.error) + "\n        ")]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }