import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import store from "./store";

Vue.use(Router);

const titleBranch = () => {
  try{
    return store.getters.jwtRole === 'UsersAccounts' ? 'Reportes' : "Sucursales"
  }catch(error){
    return "Sucursales"
  }
}

const router = new Router({
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "index",
      component: DashboardLayout,
      children: [
        {
          path: "/index",
          name: "index",
          meta: { guest: false, title: "Index" },
          component: () => import("./views/Index.vue")
        },
        {
          path: "/dashboard",
          name: "dashboard",
          meta: { guest: false, title: "Reportes" },
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import("./views/Dashboard.vue")
        },
        {
          path: "/products-zuma",
          name: "products-zuma",
          meta: { guest: false, title: "Productos Zuma" },
          component: () => import("./views/Products.vue")
        },
        {
          path: "/support",
          name: "support",
          meta: { guest: false, title: "Mantenimiento" },
          component: () => import("./views/Support.vue")
        },
        {
          path: "/tables",
          name: "tables",
          meta: { guest: false },
          component: () => import("./views/Tables.vue")
        },
        {
          path: "/branch/:idbranch",
          name: "branch",
          meta: { guest: false, title: "Datos Sucursal" },
          component: () => import("./views/BranchData.vue")
        },
        {
          path: "/clients",
          name: "clients",
          meta: { guest: false, title: "Clientes" },
          component: () => import("./views/Clients.vue")
        },
        {
          path: "/salers",
          name: "salers",
          meta: { guest: false, title: "Usuarios" },
          component: () => import("./views/Users.vue")
        },
        {
          path: "/downloads",
          name: "downloads",
          meta: { guest: false, title: "Descargas" },
          component: () => import("./views/Downloads.vue")
        },
        {
          path: "/myaccount",
          name: "myaccount",
          meta: { guest: false, title: "Mi cuenta" },
          component: () => import("./views/MyAccount.vue")
        },
        {
          path: "/company/:idwebuser/branches",
          name: "branchess",
          meta: { guest: false, title: "Sucursales" },
          component: () => import("./views/Branches.vue")
        },
        {
          path: "/my-clients",
          name: "my-clients",
          meta: { guest: false, title: "Mis Clientes" },
          component: () => import("./views/MyClients.vue")
        },
        {
          path: "/salers2",
          name: "salers2",
          meta: { guest: false, title: "Vendedores" },
          component: () => import("./views/Salers.vue")
        },
        {
          path: "/salers2/:id/:month/:year",
          name: "salers2-detail",
          meta: { guest: false, title: "Detalle" },
          component: () => import("./views/SalersDetail.vue")
        },
        {
          path: "/branch/:id/transaction-detail",
          name: "branch-transaction-detail",
          meta: {
            guest: false,
            title: "Ventas Sucursal",
            retrieveCompany: false
          },
          component: () => import("./views/BranchTransactionDetail.vue")
        },
        {
          path: "/company/:id/transaction-detail",
          name: "company-transaction-detail",
          meta: {
            guest: false,
            title: "Ventas Empresa",
            retrieveCompany: true
          },
          component: () => import("./views/BranchTransactionDetail.vue")
        },
        {
          path: "/link-generator",
          name: "link-generator",
          meta: { guest: false, title: "Generador de ligas de pago" },
          component: () => import("./views/LinkGeneratorV3.vue")
        },
        {
          path: "/account-statements",
          name: "account-statements",
          meta: { guest: false, title: "Generador de estados de cuenta" },
          component: () => import("./views/AccountStatement.vue")
        },
        {
          path: "/clients-accounts",
          name: "clients-accounts",
          meta: { guest: false, title: "Clientes" },
          component: () => import("./views/ClientsAccounts.vue")
        },
        {
          path: "/clients-external",
          name: "clients-external",
          meta: { guest: false, title: "Clientes externos" },
          component: () => import("./views/ClientsExternal.vue")
        },
        {
          path: "/admins",
          name: "admins",
          meta: { guest: false, title: "Administradores" },
          component: () => import("./views/ClientsAdmin.vue")
        },
        {
          path: "/partners",
          name: "partners",
          meta: { guest: false, title: "Vendedores" },
          component: () => import("./views/ClientsPartner.vue")
        },
        {
          path: "/accounts",
          name: "accounts",
          meta: { guest: false, title: "Cuentas" },
          component: () => import("./views/Accounts.vue")
        },
        {
          path: "/enterprises/:id?",
          name: "enterprises",
          meta: { guest: false, title: "Empresas" },
          component: () => import("./views/Enterprises.vue")
        },
        {
          path: "/branches/:id?",
          name: "branches",
          meta: { guest: false, title: titleBranch() },
          component: () => import("./views/BranchesV2.vue")
        },
        {
          path: "/devices/:id?",
          name: "devices",
          meta: { guest: false, title: "Dispositivos" },
          component: () => import("./views/Devices.vue")
        },
        {
          path: "/transactions/details",
          name: "transactions-details",
          meta: { guest: false, title: "Detalles de las transacciones" },
          component: () => import("./views/TransactionsDetails.vue")
        },
        {
          path: "/session/:idSession?/:idBranch?/:idDevice?",
          name: "session",
          meta: { guest: false, title: "Detalles de la sesión" },
          component: () => import("./views/SessionDetails.vue")
        },
        {
          path: "/account-detail/:id",
          name: "account-detail",
          meta: { guest: false, title: "Detalles de la cuenta" },
          component: () => import("./views/AccountDataV3.vue")
        },
        {
          path: "/enterprise-detail/:id",
          name: "enterprise-detail",
          meta: { guest: false, title: "Detalles de la empresa" },
          component: () => import("./views/EnterpriseDataV3.vue")
        },
        {
          path: "/branch-detail/:id",
          name: "branch-detail",
          meta: { guest: false, title: "Detalles de la sucursal" },
          component: () => import("./views/BranchDataV2.vue")
        },
        {
          path: "/partner-accounts/:id",
          name: "partner-accounts",
          meta: { guest: false, title: "Cuentas del vendedor" },
          component: () => import("./views/PartnerAccounts.vue")
        },
        {
          path: "/products/:id",
          name: "products",
          meta: { guest: false, title: "Cuentas del vendedor" },
          component: () => import("./views/ProductsDataV3.vue")
        },
        {
          path: "/productsDevice/:id",
          name: "products-device",
          meta: { guest: false, title: "Productos del dispositivo" },
          component: () => import("./views/ProductsDataDeviceV3.vue")
        },
        {
          path: "/device-detail/:id",
          name: "device-detail",
          meta: { guest: true },
          component: () => import("./views/DeviceData.vue")
        },
        {
          path: "/account-survey/:id?",
          name: "account-survey",
          meta: { guest: true },
          component: () => import("./views/SurveyData.vue")
        },
        {
          path: "/codi-generator",
          name: "codi-generator",
          meta: { guest: true },
          component: () => import("./views/CodiGenerator.vue")
        },
        {
          path: "/tickets",
          name: "tickets",
          meta: { guest: true },
          component: () => import("./views/Tickets.vue")
        },
        {
          path: "/total-session/:idSession/:idUser",
          name: "total-session",
          meta: { guest: true },
          component: () => import("./views/TotalSessionData.vue")
        }
      ]
    },
    {
      path: "/",
      redirect: "login",
      component: AuthLayout,
      children: [
        {
          path: "/login",
          name: "login",
          meta: { guest: true },
          component: () => import("./views/Login.vue")
        },
        {
          path: "/logout",
          name: "logout",
          meta: { guest: false },
          component: () => import("./views/Logout.vue")
        },
        {
          path: "/register",
          name: "register",
          meta: { guest: true },
          component: () => import("./views/Register.vue")
        },
        {
          path: "i/:code",
          name: "i",
          meta: { guest: true },
          component: () => import("./views/ZumaLink.vue")
        },
        {
          path: "/terms-conditions",
          name: "terms-conditions",
          meta: { guest: true },
          component: () => import("./views/TermsAndConditions.vue")
        }
      ]
    }
  ]
});

// https://stackoverflow.com/questions/52560021/how-to-restrict-page-access-to-unlogged-users-with-vuejs
router.beforeEach((to, from, next) => {
  if (to.meta.guest) {
    return next();
  } else {
    if (store.getters.isAuthenticated) {
      return next();
    }
  }
  return next({ path: "/login" });
});

export default router;
