var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { "nav-open": _vm.$sidebar.showSidebar } },
    [
      _c("logout-confirmation-modal", {
        attrs: { showConfirmExit: _vm.showConfirmExit },
        on: {
          close: function($event) {
            _vm.showConfirmExit = false
          }
        }
      }),
      _c(
        "side-bar",
        {
          attrs: {
            "background-color": _vm.sidebarBackground,
            "short-title": "Zuma Pagos",
            title: "Zuma Pagos"
          }
        },
        [
          _c(
            "template",
            { slot: "links" },
            [
              _vm.userRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "ZumaLink",
                        icon: "fas fa-link  text-success",
                        path: "/link-generator"
                      }
                    }
                  })
                : _vm._e(),
              _vm.userRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "CoDi",
                        icon: "fas fa-qrcode  black-color",
                        path: "/codi-generator"
                      }
                    }
                  })
                : _vm._e(),
              _vm.adminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Cuentas",
                        icon: "ni ni-collection  text-info",
                        path: "/accounts"
                      }
                    }
                  })
                : _vm._e(),
              _vm.userAdminRoles.includes(_vm.userRole) &&
              _vm.hasEnterpriseLevelAccess()
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Empresas",
                        icon: "ni ni-building  text-light",
                        path: "/enterprises"
                      }
                    }
                  })
                : _vm._e(),
              _vm.userAdminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name:
                          _vm.userRole === "UsersAccounts"
                            ? "Reportes"
                            : "Sucursales",
                        icon: "ni ni-archive-2 text-primary",
                        path: "/branches"
                      }
                    }
                  })
                : _vm._e(),
              _vm.adminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Dispositivos",
                        icon: "ni ni-mobile-button  text-purple",
                        path: "/devices"
                      }
                    }
                  })
                : _vm._e(),
              _vm.adminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Clientes Externos",
                        icon: "ni ni-single-02  text-purple",
                        path: "/clients-external"
                      }
                    }
                  })
                : _vm._e(),
              _vm.userAdminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Estado de cuenta",
                        icon: "ni ni-chart-bar-32  account-statement-color",
                        path: "/account-statements"
                      }
                    }
                  })
                : _vm._e(),
              _vm.adminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Administradores",
                        icon: "ni ni-badge text-success",
                        path: "/admins"
                      }
                    }
                  })
                : _vm._e(),
              _vm.adminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Clientes",
                        icon: "ni ni-circle-08  text-primary",
                        path: "/clients-accounts"
                      }
                    }
                  })
                : _vm._e(),
              _vm.adminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Vendedores",
                        icon: "ni ni-bag-17  text-danger",
                        path: "/partners"
                      }
                    }
                  })
                : _vm._e(),
              _vm.adminRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Tickets",
                        icon: "ni ni-email-83 text-info",
                        path: "/tickets"
                      }
                    }
                  })
                : _vm._e(),
              _vm.userRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Productos",
                        icon: "ni ni-credit-card text-info",
                        path: "/products-zuma"
                      }
                    }
                  })
                : _vm._e(),
              _vm.userRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Soporte",
                        icon: "ni ni-bullet-list-67 text-danger",
                        path: "/support"
                      }
                    }
                  })
                : _vm._e(),
              _vm.salerRoles.includes(_vm.userRole)
                ? _c("sidebar-item", {
                    attrs: {
                      link: {
                        name: "Mis Clientes",
                        icon: "ni ni-shop text-warning",
                        path: "/my-clients"
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      _vm.showConfirmExit = true
                    }
                  }
                },
                [
                  _c("i", { staticClass: "ni ni-button-power text-pink" }),
                  _c("span", { staticClass: "nav-link-text" }, [
                    _vm._v("Salir")
                  ])
                ]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "main-content", attrs: { data: _vm.sidebarBackground } },
        [
          _c("dashboard-navbar"),
          _c(
            "div",
            { on: { click: _vm.toggleSidebar } },
            [
              _c(
                "fade-transition",
                {
                  attrs: { duration: 200, origin: "center top", mode: "out-in" }
                },
                [_c("router-view", { on: { error: _vm.handleError } })],
                1
              ),
              !_vm.$route.meta.hideFooter ? _c("content-footer") : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }