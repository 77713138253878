var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fade-transition", [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "alert",
            class: [
              "alert-" + _vm.type,
              { "alert-dismissible": _vm.dismissible }
            ],
            attrs: { role: "alert" }
          },
          [
            !_vm.dismissible
              ? _vm._t("default", [
                  _vm.icon
                    ? _c("span", { staticClass: "alert-inner--icon" }, [
                        _c("i", { class: _vm.icon })
                      ])
                    : _vm._e(),
                  _vm.$slots.text
                    ? _c(
                        "span",
                        { staticClass: "alert-inner--text" },
                        [_vm._t("text")],
                        2
                      )
                    : _vm._e()
                ])
              : [
                  _vm._t("default", [
                    _vm.icon
                      ? _c("span", { staticClass: "alert-inner--icon" }, [
                          _c("i", { class: _vm.icon })
                        ])
                      : _vm._e(),
                    _vm.$slots.text
                      ? _c(
                          "span",
                          { staticClass: "alert-inner--text" },
                          [_vm._t("text")],
                          2
                        )
                      : _vm._e()
                  ]),
                  _vm._t("dismiss-icon", [
                    _c(
                      "button",
                      {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "alert",
                          "aria-label": "Close"
                        },
                        on: { click: _vm.dismissAlert }
                      },
                      [
                        _c("span", { attrs: { "aria-hidden": "true" } }, [
                          _vm._v("×")
                        ])
                      ]
                    )
                  ])
                ]
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }