 // Little extention to filter input type on prop validation
 export default {
  props: {
    validation: {
      type: String,
      description: "Type of validation, currently only number is implemented"
    }
  },
  methods: {
    inputValidation($event) {
      if (this.validation == 'number') {
        return this.onlyNumber($event);
      }
      if (this.isUserName){
        return this.userName($event)
      }
      return this.noOp();
    },
    userName($event){
      var regex = new RegExp("^[a-zA-Z0-9._-]");    
      if (!regex.test($event.key))
        $event.preventDefault()
    },
    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode > 31 && (keyCode < 48 || keyCode > 57)) && keyCode !== 46) {
        $event.preventDefault();
      }
      
    },
    noOp() {

    },
  }
 }