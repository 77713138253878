var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table tablesorter", class: _vm.tableClass },
    [
      _c("thead", { class: _vm.theadClasses }, [
        _c(
          "tr",
          [
            _vm._t(
              "columns",
              _vm._l(_vm.columns, function(column) {
                return _c("th", { key: column }, [_vm._v(_vm._s(column))])
              }),
              { columns: _vm.columns }
            )
          ],
          2
        )
      ]),
      _c(
        "tbody",
        { class: _vm.tbodyClasses },
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "tr",
            { key: index },
            [
              _vm._t(
                "default",
                _vm._l(_vm.colsWithValue(item), function(column, index) {
                  return _c("td", { key: index }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.itemValue(item, column)) +
                        "\n        "
                    )
                  ])
                }),
                { row: item, index: index }
              )
            ],
            2
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }