var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card",
    {
      staticClass: "card-stats",
      attrs: { "show-footer-line": true },
      on: {
        "card-click": function($event) {
          return _vm.$emit("card-click")
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _vm._t("default", [
              _vm.title
                ? _c(
                    "h5",
                    {
                      staticClass: "card-title text-uppercase text-muted mb-0"
                    },
                    [_vm._v(_vm._s(_vm.title))]
                  )
                : _vm._e(),
              _vm.subTitle
                ? _c("span", { staticClass: "h2 font-weight-bold mb-0" }, [
                    _vm._v(_vm._s(_vm.subTitle))
                  ])
                : _vm._e()
            ])
          ],
          2
        ),
        _vm.$slots.icon || _vm.icon
          ? _c(
              "div",
              { staticClass: "col-auto" },
              [
                _vm._t("icon", [
                  _c("i", {
                    staticClass: "big-icon",
                    class: [_vm.icon, "" + _vm.type, _vm.iconClasses]
                  })
                ])
              ],
              2
            )
          : _vm._e()
      ]),
      _c("p", { staticClass: "mt-3 mb-0 text-sm" }, [_vm._t("footer")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }