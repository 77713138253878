<template>
    <base-select
      label="Estado"
      :options="states"
      :v-model="value"
      id="estate"
      required
      @blur="updateSelection()"
    />
</template>
<script>
export default {
  name: "state-list",
  props: {
    value: {
      type: [Array, String, Number],
      description: "Select value"
    },
  },
  data() {
    return {
      states: [
        { key: "AGUASCALIENTES", value: "AGUASCALIENTES"} ,
        { key: "BAJA CALIFORNIA", value: "BAJA CALIFORNIA"} ,
        { key: "BAJA CALIFORNIA SUR", value: "BAJA CALIFORNIA SUR"} ,
        { key: "CAMPECHE", value: "CAMPECHE"} ,
        { key: "CHIAPAS", value: "CHIAPAS"} ,
        { key: "CHIHUAHUA", value: "CHIHUAHUA"} ,
        { key: "CIUDAD DE MEXICO", value: "CIUDAD DE MEXICO"} ,
        { key: "COAHUILA", value: "COAHUILA"} ,
        { key: "COLIMA", value: "COLIMA"} ,
        { key: "DURANGO", value: "DURANGO"} ,
        { key: "ESTADO DE MEXICO", value: "ESTADO DE MEXICO"} ,
        { key: "GUANAJUATO", value: "GUANAJUATO"} ,
        { key: "GUERRERO", value: "GUERRERO"} ,
        { key: "HIDALGO", value: "HIDALGO"} ,
        { key: "JALISCO", value: "JALISCO"} ,
        { key: "MICHOACAN", value: "MICHOACAN"} ,
        { key: "MORELOS", value: "MORELOS"} ,
        { key: "NAYARIT", value: "NAYARIT"} ,
        { key: "NUEVO LEON", value: "NUEVO LEON"} ,
        { key: "OAXACA", value: "OAXACA"} ,
        { key: "PUEBLA", value: "PUEBLA"} ,
        { key: "QUERÉTARO", value: "QUERÉTARO"} ,
        { key: "QUINTANA ROO", value: "QUINTANA ROO"} ,
        { key: "SAN LUIS POTOSI", value: "SAN LUIS POTOSI"} ,
        { key: "SINALOA", value: "SINALOA"} ,
        { key: "SONORA", value: "SONORA"} ,
        { key: "TABASCO", value: "TABASCO"} ,
        { key: "TAMAULIPAS", value: "TAMAULIPAS"} ,
        { key: "TLAXCALA", value: "TLAXCALA"} ,
        { key: "VERACRUZ", value: "VERACRUZ"} ,
        { key: "YUCATAN", value: "YUCATAN"} ,
        { key: "ZACATECAS", value: "ZACATECAS"} ,
      ]
    }
  },
  methods: {
    updateSelection(event){
      this.$emit('input', event);
    }
  }
}
</script>