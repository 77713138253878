<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <logout-confirmation-modal
       v-on:close="showConfirmExit=false"
       :showConfirmExit="showConfirmExit"
    ></logout-confirmation-modal>
    <side-bar
      :background-color="sidebarBackground"
      short-title="Zuma Pagos"
      title="Zuma Pagos"
    >
      <template slot="links">
        <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'ZumaLink', icon: 'fas fa-link  text-success', path: '/link-generator'}"/>
        <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'CoDi', icon: 'fas fa-qrcode  black-color', path: '/codi-generator'}"/>
        <sidebar-item v-if="adminRoles.includes(userRole)" :link="{name: 'Cuentas', icon: 'ni ni-collection  text-info', path: '/accounts'}"/>
        <sidebar-item v-if="userAdminRoles.includes(userRole) && hasEnterpriseLevelAccess()" :link="{name: 'Empresas', icon: 'ni ni-building  text-light', path: '/enterprises'}"/>
        <sidebar-item v-if="userAdminRoles.includes(userRole)" :link="{name: userRole === 'UsersAccounts' ? 'Reportes' : 'Sucursales', icon: 'ni ni-archive-2 text-primary', path: '/branches'}"/>
        <sidebar-item v-if="adminRoles.includes(userRole)" :link="{name: 'Dispositivos', icon: 'ni ni-mobile-button  text-purple', path: '/devices'}"/>
        <sidebar-item v-if="adminRoles.includes(userRole)" :link="{name: 'Clientes Externos', icon: 'ni ni-single-02  text-purple', path: '/clients-external'}"/>
        <sidebar-item v-if="userAdminRoles.includes(userRole)" :link="{name: 'Estado de cuenta', icon: 'ni ni-chart-bar-32  account-statement-color', path: '/account-statements'}"/>
        <sidebar-item v-if="adminRoles.includes(userRole)" :link="{name: 'Administradores', icon: 'ni ni-badge text-success', path: '/admins'}"/>
        <sidebar-item v-if="adminRoles.includes(userRole)" :link="{name: 'Clientes', icon: 'ni ni-circle-08  text-primary', path: '/clients-accounts'}"/>
        <sidebar-item v-if="adminRoles.includes(userRole)" :link="{name: 'Vendedores', icon: 'ni ni-bag-17  text-danger', path: '/partners'}"/>
        <sidebar-item v-if="adminRoles.includes(userRole)" :link="{name: 'Tickets', icon: 'ni ni-email-83 text-info', path: '/tickets'}"/>
        <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'Productos', icon: 'ni ni-credit-card text-info', path: '/products-zuma'}"/>
        <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'Soporte', icon: 'ni ni-bullet-list-67 text-danger', path: '/support'}"/>
        <!-- <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'Encuestas', icon: 'fas fa-poll icon-size text-danger', path: '/account-survey'}"/> -->
        <sidebar-item v-if="salerRoles.includes(userRole)" :link="{name: 'Mis Clientes', icon: 'ni ni-shop text-warning', path: '/my-clients'}"/>
        <!-- <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'Reportes',  icon: 'ni ni-tv-2 text-primary',  path: '/dashboard'}"/>
        <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'ZumaLink', icon: 'ni ni-building  text-red', path: '/link-generator'}"/>
        <sidebar-item :link="{name: 'Estado de cuenta', icon: 'ni ni-chart-bar-32  text-red', path: '/account-statements'}"/>
        <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'Mantenimiento', icon: 'ni ni-bullet-list-67 text-red', path: '/support'}"/>
        <sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'Productos', icon: 'ni ni-credit-card text-blue', path: '/products'}"/> -->
        <!--sidebar-item v-if="userRoles.includes(userRole)" :link="{name: 'Mi Cuenta', icon: 'ni ni-bullet-list-67 text-yellow', path: '/myaccount'}"/-->
        <!-- <sidebar-item v-if="variousAdminRoles.includes(userRole)" :link="{name: 'Clientes', icon: 'ni ni-building  text-red', path: '/clients'}"/>
        <sidebar-item v-if="variousAdminRoles.includes(userRole)" :link="{name: 'Vendedores', icon: 'ni ni-badge text-blue', path: '/salers2'}"/>
        <sidebar-item v-if="variousAdminRoles.includes(userRole)" :link="{name: 'Usuarios', icon: 'ni ni-shop text-info', path: '/salers'}"/>
        <sidebar-item v-if="salerRoles.includes(userRole)" :link="{name: 'Mis Clientes', icon: 'ni ni-shop text-info', path: '/my-clients'}"/> -->
        <a href="#" class="nav-link" @click="showConfirmExit=true">
          <i class="ni ni-button-power text-pink"></i>
          <span class="nav-link-text">Salir</span>
        </a>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view
            v-on:error="handleError"
          >
          </router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import LogoutConfirmationModal from '../views/Modals/LogoutConfirmationModal';
  import { FadeTransition } from 'vue2-transitions';
  import enumRoles from '../enums/enumRoles';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition,
      LogoutConfirmationModal
    },
    data() {
      return {
        sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
        adminRoles: ['UsersAdmins'],
        variousAdminRoles: ['UsersAdmins'],
        userAdminRoles: ['UsersAccounts', 'UsersAdmins'],
        userRoles: ['UsersAccounts'],
        salerRoles: ['UsersPartners'],
        showConfirmExit: false,
      };
    },
    computed: {
      userRole() {
        return this.$store.getters.jwtRole;
      },
      userLevel() {
        return this.$store.getters.jwtUserLevel;
      },
    },
    methods: {
      hasEnterpriseLevelAccess(){
        if (this.userRole === enumRoles.ACCOUNT){
          switch(this.userLevel) {
            case 'Account':
              return true
            case 'Enterprise':
              return true
            case 'Branch':
              return false
          }
        }
        return true
      },
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      handleError(event) {
        try{
          var message = event.response.data.message
          if (message !== undefined && message !== '') {
            this.$toasted.show(message);
          }else{
             message = event.message
            if (message !== undefined && message !== '') {
              this.$toasted.show(message);
            }
          }
        }catch(error){
          let message = event.message;
          if(event === 'Network Error'){
            message = 'No hay coneccion con el servidor, revise su coneccion a internet';
          }
          this.$toasted.show(message);
        }
      },
    }
  };
</script>
<style lang="scss">
.black-color {
  color: #000000;
}
.account-statement-color {
  color: #ff8800;
}
</style>
