var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card",
      class: [
        { "card-lift--hover": _vm.hover },
        { shadow: _vm.shadow },
        ((_obj = {}),
        (_obj["shadow-" + _vm.shadowSize] = _vm.shadowSize),
        _obj),
        ((_obj$1 = {}),
        (_obj$1["bg-gradient-" + _vm.gradient] = _vm.gradient),
        _obj$1),
        ((_obj$2 = {}), (_obj$2["bg-" + _vm.type] = _vm.type), _obj$2)
      ],
      on: {
        click: function($event) {
          return _vm.$emit("card-click")
        }
      }
    },
    [
      _vm.$slots.header
        ? _c(
            "div",
            { staticClass: "card-header", class: _vm.headerClasses },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      !_vm.noBody
        ? _c(
            "div",
            { staticClass: "card-body", class: _vm.bodyClasses },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.noBody ? _vm._t("default") : _vm._e(),
      _vm.$slots.footer
        ? _c(
            "div",
            { staticClass: "card-footer", class: _vm.footerClasses },
            [_vm._t("footer")],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }