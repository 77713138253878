import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth'
import user from './modules/user'
import branch from './modules/branch'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    user,
    branch,
  },
  strict: debug,
  plugins: [createPersistedState()],
})
