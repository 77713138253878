/* eslint-disable promise/param-names */
import { USER_INFO_REQUEST, USER_INFO_REQUEST_SUCCESS, USER_INFO_REQUEST_ERROR, USER_INFO_CLEAR, UPDATE_USER_INFO } from '../actions/user';
import { getOwnData } from '../../utils/api';

const state = { userInfo:  {}, status: '', hasLoadedOnce: false, err: '' };

const Strings = {};
Strings.orEmpty = function( entity ) {
    return entity || "";
};

const getters = {
  fullName: state => Strings.orEmpty(state.userInfo.fullname) 
};

const actions = {
  [USER_INFO_REQUEST]: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit(USER_INFO_REQUEST);
      getOwnData()
      .then(resp => {
        commit(USER_INFO_REQUEST_SUCCESS, resp.data);
        resolve(resp);
      })
      .catch(err => {
        commit(USER_INFO_REQUEST_ERROR, err);
        reject(err);
      });
    });
  },
  [UPDATE_USER_INFO]: ({commit}, user) => {
    commit(USER_INFO_REQUEST_SUCCESS, {result: user});
  },
   [USER_INFO_CLEAR]: ({commit}) => {
    return new Promise((resolve) => {
      commit(USER_INFO_CLEAR);
      resolve();
    })
  },
};

const mutations = {
  [USER_INFO_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [USER_INFO_REQUEST_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.userInfo = resp.result;
    state.hasLoadedOnce = true;
  },
  [USER_INFO_REQUEST_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true;
  },
  [USER_INFO_CLEAR]: (state) => {
    state.status='';
    state.hasLoadedOnce=false;
    state.userInfo={};
    state.err='';
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
