<template>
  <div class="main-content bg-default-zuma">
    <!-- Navbar -->

    <!-- Header -->
    <div id="gradient-header" class="header bg-gradient-app-zuma py-8 py-lg-8">
      <div class="container">
        <div class="header-body text-center mt--7 pb-5">
          <a slot="brand" class="navbar-brand no-margin" href="http://zumapagos.com">
            <img src="img/brand/white-logo.png" width="100%" />
          </a>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-default-zuma" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--7 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-6">
      <div class="container">
        <div class="align-items-center">
          <div class="col-xl">
            <div class="copyright text-center">
              &copy; {{year}}
              <a
                href="https://www.zumapagos.com"
                class="font-weight-bold ml-1"
                target="_blank"
              >Zuma Pagos</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition
  },
  methods: {
    verifyDomain() {
      let domain = window.location.hostname;
      let adminDomain = "admin1.dashboardzuma.com";

      if (domain === adminDomain) {
        document.getElementById("gradient-header").className =
          "header bg-gradient-admin-zuma py-8 py-lg-8";
      } else {
        document.getElementById("gradient-header").className =
          "header bg-gradient-app-zuma py-8 py-lg-8";
      }
    }
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false
    };
  },
  mounted() {
    this.verifyDomain();
  }
};
</script>
<style>
.bg-gradient-app-zuma {
  background: linear-gradient(
    87deg,
    rgba(48, 34, 119, 1) 0%,
    rgba(48, 34, 119, 0.5) 100%
  ) !important;
}
.bg-gradient-admin-zuma {
  background: linear-gradient(87deg, #000 0%, #fff 100%) !important;
}
.bg-default-zuma {
  background-color: #eee !important;
}
.fill-default-zuma {
  fill: #eee !important;
}
.no-margin {
  margin-right: 0 !important;
}
</style>
