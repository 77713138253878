/* eslint-disable promise/param-names */
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_REFRESH, AUTH_CLEAR, REFRESH_SUCCESS, AUTH_REFRESH_TOKEN } from '../actions/auth';
import { refreshToken, tokenCall } from '../../utils/api';

import {ACCESS_TOKEN_STORAGE_KEY , ACCESS_REFRESH_TOKEN_STORAGE_KEY} from '../../utils/consts';

const state = { token: localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || '', status: '', hasLoadedOnce: false };

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  jwt: state => state.token,
  jwtData: (state, getters) => {
    try{
      return getters.jwt.split('.')[1] ? JSON.parse(atob(getters.jwt.split('.')[1])) : null
    }catch(e){
      return null
    }
  },
  jwtUsername: (state, getters) => getters.jwtData ? getters.jwtData.username : null,
  jwtRole: (state, getters) => getters.jwtData ? getters.jwtData.userType : null,
  jwtUserLevel: (state, getters) => getters.jwtData ? getters.jwtData.userLevel : null,
};

const actions = {
  [AUTH_REQUEST]: ({commit}, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      tokenCall(user)
      .then(resp => {
        if(resp.data.statusCode == 200){
          localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, resp.data.result.accessToken);
          localStorage.setItem(ACCESS_REFRESH_TOKEN_STORAGE_KEY, resp.data.result.refreshToken);
          commit(AUTH_SUCCESS, resp);
          resolve(resp);
        }else{
          commit(AUTH_ERROR, resp);
          localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
          reject(resp);
        }
      })
      .catch(err => {
        commit(AUTH_ERROR, err);
        localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
        reject(err);
      });
    });
  },
  [AUTH_REFRESH_TOKEN]: ({commit}) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      refreshToken(localStorage.getItem(ACCESS_REFRESH_TOKEN_STORAGE_KEY))
      .then(resp => {
        localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, resp.data.result.accessToken);
        localStorage.setItem(ACCESS_REFRESH_TOKEN_STORAGE_KEY, resp.data.result.refreshToken);
        commit(AUTH_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        commit(AUTH_ERROR, err);
        localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
        reject(err);
      });
    });
  },
  [AUTH_LOGOUT]: ({commit}) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      localStorage.removeItem(ACCESS_REFRESH_TOKEN_STORAGE_KEY);
      resolve();
    })
  },
   [AUTH_CLEAR]: ({commit}) => {
    return new Promise((resolve) => {
      commit(AUTH_CLEAR);
      localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
      localStorage.removeItem(ACCESS_REFRESH_TOKEN_STORAGE_KEY);
      resolve();
    })
  },
  [AUTH_REFRESH]: ({commit}, token) => {
    return new Promise((resolve) => {
      commit(AUTH_REFRESH);
      localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
      commit(REFRESH_SUCCESS, token);
      resolve();
    });
  },
};

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading';
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.token = resp.data.result.accessToken;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: (state) => {
    state.status = 'error';
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.token = '';
  },
  [AUTH_CLEAR]: (state) => {
    state.token = '';
    state.status= '';
  },

  [AUTH_REFRESH]: (state) => {
    state.status = 'loading';
  },
  [REFRESH_SUCCESS]: (state, token) => {
    state.status = 'success';
    state.token = token;
    state.hasLoadedOnce = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
}
