var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer px-4" }, [
    _c(
      "div",
      { staticClass: "row align-items-center justify-content-lg-between" },
      [
        _c("div", { staticClass: "col-lg-6" }, [
          _c(
            "div",
            { staticClass: "copyright text-center text-lg-left text-muted" },
            [
              _vm._v("\n        Copyright © " + _vm._s(_vm.year) + " "),
              _c(
                "a",
                {
                  staticClass: "font-weight-bold ml-1",
                  attrs: {
                    href: "https://www.zumapagos.com/",
                    target: "_blank"
                  }
                },
                [_vm._v("by ZUMA Pagos")]
              )
            ]
          )
        ]),
        _vm._m(0)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-6" }, [
      _c("ul", {
        staticClass:
          "nav nav-footer justify-content-center justify-content-xl-end"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }