var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar",
      class: [
        { "navbar-expand-md": _vm.expand },
        { "navbar-transparent": _vm.transparent },
        ((_obj = {}), (_obj["bg-" + _vm.type] = _vm.type), _obj)
      ]
    },
    [
      _c(
        "div",
        { class: _vm.containerClasses },
        [
          _vm._t("brand", [
            _c(
              "router-link",
              {
                staticClass:
                  "h4 mb-0 text-white text-uppercase d-none d-lg-inline-block",
                attrs: { to: _vm.$route.path }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$route.meta.title) +
                    "\n            "
                )
              ]
            )
          ]),
          _vm.showToggleButton
            ? _c(
                "navbar-toggle-button",
                {
                  attrs: { toggled: _vm.toggled, target: _vm.contentId },
                  nativeOn: {
                    click: function($event) {
                      $event.stopPropagation()
                      _vm.toggled = !_vm.toggled
                    }
                  }
                },
                [_c("span", { staticClass: "navbar-toggler-icon" })]
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeMenu,
                  expression: "closeMenu"
                }
              ],
              staticClass: "collapse navbar-collapse",
              class: { show: _vm.toggled },
              attrs: { id: _vm.contentId }
            },
            [_vm._t("default", null, { closeMenu: _vm.closeMenu })],
            2
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }