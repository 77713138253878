<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          Copyright © {{year}} <a href="https://www.zumapagos.com/" class="font-weight-bold ml-1" target="_blank">by ZUMA Pagos</a>
        </div>
      </div>
      <div class="col-lg-6">
        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
            <!--li class="nav-item">
                <a href="https://www.zumapagos.com/services" class="nav-link" target="_blank">Servicios</a>
            </li-->
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
  .footer{
    background: #f8f9fe !important;
  }
</style>
