var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white",
      attrs: { id: "sidenav-main" }
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c(
            "navbar-toggle-button",
            {
              nativeOn: {
                click: function($event) {
                  return _vm.showSidebar($event)
                }
              }
            },
            [_c("span", { staticClass: "navbar-toggler-icon" })]
          ),
          _c(
            "router-link",
            { staticClass: "navbar-brand", attrs: { to: "/" } },
            [
              _c("img", {
                staticClass: "navbar-brand-img",
                attrs: { src: "img/brand/green.png", alt: "..." }
              })
            ]
          ),
          _vm._t("mobile-right", [
            _c(
              "ul",
              { staticClass: "nav align-items-center d-md-none" },
              [
                _c(
                  "base-dropdown",
                  { staticClass: "nav-item", attrs: { position: "right" } },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: { slot: "title", href: "#", role: "button" },
                        slot: "title"
                      },
                      [
                        _c("div", { staticClass: "media align-items-center" }, [
                          _c(
                            "span",
                            { staticClass: "avatar avatar-sm rounded-circle" },
                            [_c("i", { staticClass: "ni ni-circle-08" })]
                          )
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "dropdown-header noti-title" }, [
                      _c("h6", { staticClass: "text-overflow m-0" }, [
                        _vm._v("Bienvenido!")
                      ])
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: "/myaccount" }
                      },
                      [
                        _c("i", { staticClass: "ni ni-single-02" }),
                        _c("span", [_vm._v("Mi cuenta")])
                      ]
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: "/logout" }
                      },
                      [
                        _c("i", { staticClass: "ni ni-user-run" }),
                        _c("span", [_vm._v("Salir")])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._t("default"),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$sidebar.showSidebar,
                  expression: "$sidebar.showSidebar"
                }
              ],
              staticClass: "navbar-collapse collapse show",
              attrs: { id: "sidenav-collapse-main" }
            },
            [
              _c("div", { staticClass: "navbar-collapse-header d-md-none" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 collapse-brand" },
                    [
                      _c("router-link", { attrs: { to: "/" } }, [
                        _c("img", { attrs: { src: _vm.logo } })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 collapse-close" },
                    [
                      _c("navbar-toggle-button", {
                        nativeOn: {
                          click: function($event) {
                            return _vm.closeSidebar($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("ul", { staticClass: "navbar-nav" }, [_vm._t("links")], 2)
            ]
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }