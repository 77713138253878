<template>
    <base-nav class="navbar-top navbar-dark text-left"
              id="navbar-main"
              :show-toggle-button="false"
              expand>
         <logout-confirmation-modal
            v-on:close="showConfirmExit=false"
            :showConfirmExit="showConfirmExit"
          ></logout-confirmation-modal>
        <ul class="navbar-nav align-items-center mr-3 d-none d-md-flex ml-lg-auto">
            <li class="nav-item dropdown">
                <base-dropdown class="nav-link pr-0">
                    <div class="media align-items-center nav-bar-menu" slot="title">
                      
                      <i class="ni ni-circle-08 rounded-circle big-icon"></i>
                      <div class="media-body ml-2 d-none d-lg-block">
                          <span class="mb-0 text-lg  font-weight-bold">{{fullName || padding}}</span>
                      </div>
                    </div>
                    <template>
                        <div class=" dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Bienvenido!</h6>
                        </div>
                        <router-link to="/myaccount" class="dropdown-item">
                          <i class="ni ni-single-02"></i>
                          <span>Mi cuenta</span>
                        </router-link>
                        <a href="#" class="dropdown-item" @click="showConfirmExit=true">
                            <i class="ni ni-user-run"></i>
                            <span>Salir</span>
                        </a>
                    </template>
                </base-dropdown>
            </li>
        </ul>
    </base-nav>
</template>
<script>
  import LogoutConfirmationModal from '../views/Modals/LogoutConfirmationModal';

  export default {
    components: {
      LogoutConfirmationModal
    },
    data() {
      return {
        activeNotifications: false,
        showMenu: false,
        searchQuery: '',
        showConfirmExit: false,
      };
    },
    methods: {
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      }
    },
    computed: {
      fullName(){
        return this.$store.getters.fullName;
      },
      padding(){
        return "               ";
      }
    },
  };
</script>
<style lang="scss" scoped>
  .nav-bar-menu { cursor: pointer; }
  .big-icon { font-size: 2em !important;}
</style>
