var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-wrapper" }, [
    _vm.$slots.label || _vm.label || _vm.$slots.percentage || _vm.showPercentage
      ? _c("div", { staticClass: "progress-info" }, [
          _vm.$slots.label || _vm.label
            ? _c("div", { staticClass: "progress-label" }, [
                _c(
                  "span",
                  [
                    _vm._t("label", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.label) +
                          "\n            "
                      )
                    ])
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.$slots.percentage || _vm.showPercentage
            ? _c(
                "div",
                { staticClass: "progress-percentage" },
                [
                  _vm._t("percentage", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.value) +
                        " %\n            "
                    )
                  ])
                ],
                2
              )
            : _vm._e()
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "progress", style: "height: " + _vm.height + "px" },
      [
        _c("div", {
          staticClass: "progress-bar",
          class: _vm.computedClasses,
          style: "width: " + _vm.value + "%;",
          attrs: {
            role: "progressbar",
            "aria-valuenow": _vm.value,
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }