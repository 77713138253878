var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-nav",
    {
      staticClass: "navbar-top navbar-dark text-left",
      attrs: { id: "navbar-main", "show-toggle-button": false, expand: "" }
    },
    [
      _c("logout-confirmation-modal", {
        attrs: { showConfirmExit: _vm.showConfirmExit },
        on: {
          close: function($event) {
            _vm.showConfirmExit = false
          }
        }
      }),
      _c(
        "ul",
        {
          staticClass:
            "navbar-nav align-items-center mr-3 d-none d-md-flex ml-lg-auto"
        },
        [
          _c(
            "li",
            { staticClass: "nav-item dropdown" },
            [
              _c(
                "base-dropdown",
                { staticClass: "nav-link pr-0" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "media align-items-center nav-bar-menu",
                      attrs: { slot: "title" },
                      slot: "title"
                    },
                    [
                      _c("i", {
                        staticClass: "ni ni-circle-08 rounded-circle big-icon"
                      }),
                      _c(
                        "div",
                        { staticClass: "media-body ml-2 d-none d-lg-block" },
                        [
                          _c(
                            "span",
                            { staticClass: "mb-0 text-lg  font-weight-bold" },
                            [_vm._v(_vm._s(_vm.fullName || _vm.padding))]
                          )
                        ]
                      )
                    ]
                  ),
                  [
                    _c("div", { staticClass: " dropdown-header noti-title" }, [
                      _c("h6", { staticClass: "text-overflow m-0" }, [
                        _vm._v("Bienvenido!")
                      ])
                    ]),
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: { to: "/myaccount" }
                      },
                      [
                        _c("i", { staticClass: "ni ni-single-02" }),
                        _c("span", [_vm._v("Mi cuenta")])
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "dropdown-item",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            _vm.showConfirmExit = true
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "ni ni-user-run" }),
                        _c("span", [_vm._v("Salir")])
                      ]
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }