/* tslint:disable:no-string-literal */
import axios from 'axios';
import router from './router';
import store from './store';


import { AUTH_CLEAR, AUTH_REFRESH, AUTH_REFRESH_TOKEN } from './store/actions/auth.js';
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 20000,
});

function getAuthToken() {
  let authTokenRequest = store.state['auth'].token;
  if (authTokenRequest === null) {
    authTokenRequest = axiosInstance
      .post('/auth/refresh', {}, { withCredentials: true })
      .then( (response) => {
        // request complete and store
        store.commit('auth/refresh', response.data.access_token);
        return response.data.access_token;
      });
  }
  return authTokenRequest;
}

// Axios Intercept Requests
axiosInstance.interceptors.request.use(
  async (config) => {    
    if ( isPageThatNotNeedsToken(config.url)) {      
      config.headers['Authorization'] = (await getAuthToken());
      /** Guardar el nuevo token */
    } else {
      const token = store.getters.jwt;
      if (typeof token !== 'undefined') {
        config.headers['Authorization'] = token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

function isPageThatNotNeedsToken(requestedUrl){
  return requestedUrl.includes('login') &&
    requestedUrl.includes('refresh') &&
    requestedUrl.includes('forgot_password') &&
    requestedUrl.includes('reset_password') &&
    requestedUrl.includes('activate') &&
    requestedUrl.includes('i') &&
    requestedUrl.includes('token');
}

axiosInstance.interceptors.response.use(
  (response) => {
    if(response.headers.token === undefined || response.headers.token === null) {
      store.dispatch(AUTH_REFRESH, store.getters.jwt);
      return response;
    }    
    store.dispatch(AUTH_REFRESH, response.headers.token);
    return response;
  },
  (error) => {
    // Prevent endless redirects (login is where you should end up)
    if (error.request !== undefined) {
      if (error.request.responseURL.includes('login')) {
        return Promise.reject(error);
      }
    }

    // If you can't refresh your token or you are sent Unauthorized on any request, logout and go to login
    if (
      error.request !== undefined &&
      (error.request.responseURL.includes('refresh') ||
        (error.request.status === 401 && error.config.__isRetryRequest))
    ) {
      store.dispatch('AUTH_LOGOUT');
      router.push({ name: 'Login' });
    } else if (error.request !== undefined && error.request.status === 401) {
      error.config.__isRetryRequest = true;
      localStorage.removeItem('access_token');
      store.dispatch(AUTH_CLEAR);
      router.push('/login');
      return axios.request(error.config);
    }else if(error.request.status === 401){
      store.dispatch(AUTH_REFRESH_TOKEN, store.getters.jwt);      
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
